import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading, MemberInformations, PageTitle, SearchCount } from "../../../../../components";
import { getDateWithDayDifference } from "../../../../../components/Utility";
import { Brands } from "../../../../../consts/Constants";
import useAuth from "../../../../../hooks/Log/useAuth";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import useMemberPhoneNumber from "../../../../../hooks/Member/useMemberPhoneNumber";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { url } from "../../../../../routes/utility";
import { GetEmployeeCallRecords } from "../../../../../services/Employee/EmployeeService";
import CallRecordsDetailSearch from "./CallRecordsDetailSearch";
import CallRecordsTable from "./CallRecordsTable";
import { GetRecordingPath } from "../../../../../services/Esneks/EsneksService";

const CallRecords = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const { memberId } = useParams() || false;
    const { employee } = useAuth();
    const memberBrandId = useMemberBrand(memberId);
    const memberPhoneNumber = useMemberPhoneNumber(memberId);

    const [loading, setLoading] = useState(true);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);
    // Son 30 Gün Seçeneği Seçili getiriliyor
    const [detailSearch, setDetailSearch] = useState({
        beginDate: new Date(getDateWithDayDifference(new Date(), 30).setHours(0, 0, 0, 0)).toISOString().split('.')[0],
        endDate: new Date(new Date().setHours(23, 59, 59, 59)).toISOString().split('.')[0],
        corporateEmail: "",
        employeeId: ""
    });
    const [esneksRecordingPath, setEsneksRecordingPath] = useState("");

    //dropdown değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
    };

    //Öğreciye ait arama bilgileri getiriliyor.
    const getCallRecords = async (detailSearch, memberPhoneNumber) => {
        setLoading(true);

        const model = {                
            startDate: detailSearch.beginDate,
            endDate: detailSearch.endDate,
            agentUsername : detailSearch.corporateEmail ?? "",
            employeeId: detailSearch.employeeId ?? "",
            memberId: memberId,
            memberPhoneNumber : memberPhoneNumber,
            timeZone: +employee?.TimeZone
        };

        const result = await GetEmployeeCallRecords(model);

        if(result.status === 200){
            setData(result.content);
            setTotalRecord(result.content.length);
        }

        setLoading(false);
    }

    useEffect(() => {
        let abortController = new AbortController();

        if(memberPhoneNumber.length > 0)
            getCallRecords(detailSearch, memberPhoneNumber);

        return () => {
            abortController.abort();
        }

    }, [detailSearch, memberPhoneNumber])

    //KO olmayan markalardan sayfaya girmeye çalışan olursa sayfadan atıyoruz.
    useEffect(() => {
        if(memberBrandId !== -1 && memberBrandId !== Brands.KO)
            navigate(url("member.detail.profile", { memberId: memberId }));
    }, [memberBrandId]);

    useEffect(() => {
        GetRecordingPath()
            .then(result => {
                if(result.status === 200)
                    setEsneksRecordingPath(result.content);
                else {
                    const defaultBaseDir = "/var/lib/3cxpbx/Instance1/Data/Recordings/";
                    setEsneksRecordingPath(defaultBaseDir);
                } 
            })
            .catch()
    }, []);

    return (
        <>
            <CallRecordsDetailSearch
                changeDetail={changeDetail}
                loading={loading}
            />

            <div className="overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                <div className="inline-block min-w-full rounded-lg overflow-hidden py-6">
                    {!loading && <SearchCount count={totalRecord} />}

                    <PageTitle title={strings.member.member_detail.header.call_records} />

                    <MemberInformations memberId={memberId} />

                    {loading && <Loading />}

                    {!loading && 
                        <CallRecordsTable 
                            data={data} 
                            esneksRecordingPath={esneksRecordingPath}
                        />
                    }
                </div>
            </div>
        </>
    );
}


export default CallRecords;