import React, { useEffect, useState } from "react";
import { LoadingSpinner } from "../../../../../components";
import {
    convertSecondsToMinutesAndSeconds,
    isItSpecified,
    ISODateToReadableDateWithSeconds,
    maskPhoneNumber
} from "../../../../../components/Utility";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import { GetRecordingInfo } from "../../../../../services/Alotech/AlotechService";

const CallRecordsTableRow = ({ callInfo, esneksRecordingPath }) => {

    const strings = useLocalization();
    const notSpecified = strings.errors.it_is_not_specified;

    const [loading, setLoading] = useState(true);
    const [callRecord, setCallRecord] = useState("");

    //Arama durumuna göre localization ve className belirleniyor.
    const getAnswerStatusClassnames = (answerStatus) => {

        let string;
        let className;

        switch (answerStatus) {
            case "NOANSWER":
                string = strings.member.member_detail.call_records.no_answer
                className = "text-red"
                break;
            case "ANSWER":
                string = strings.member.member_detail.call_records.answer
                className = "text-green"
                break;
            case "BUSY":
                string = strings.member.member_detail.call_records.busy
                className = "text-warning"
                break;
            case "CANCEL":
                string = strings.member.member_detail.call_records.cancel
                className = "text-danger"
                break;
            case "CONGESTION":
                string = strings.member.member_detail.call_records.congestion
                className = "text-danger"
                break;
            default:
                string = isItSpecified(answerStatus, notSpecified)
                className = "text-base-text"
        }

        return {
            string: string,
            className: className
        }
    }

    const getCallRecordLink = async (recordingUrl, callCompany) => {
        if(callCompany === "esneks"){
            if(!recordingUrl) {
                setCallRecord("");
                setLoading(false);
                return;
            } 

            const esneksBaseCallRecordUrl = "https://konusarakogren.3cx.com.tr:4444/downloadrecord";

            // URL decode işlemleri ses kayıtlarını real-time dinletebilmek için yapılmıştır. Esneks firması özelinde geçerlidir.
            const encodedBaseDir = encodeURIComponent(esneksRecordingPath + "/");

            const splittedCallRecordUrl = recordingUrl.split("/");
            const encodedAgentNumberDir = encodeURIComponent(splittedCallRecordUrl[0] + "/") + "/";
            
            const splittedCallRecordUrlSecondPart = splittedCallRecordUrl[1].split("_");
            const encodedAgentName = encodeURIComponent(encodeURIComponent(splittedCallRecordUrlSecondPart[0]));
                
            const callRecordUrl = `${esneksBaseCallRecordUrl}/${encodedBaseDir}${encodedAgentNumberDir}${encodedAgentName}_${splittedCallRecordUrlSecondPart[1]}_${splittedCallRecordUrlSecondPart[2]}`;
            setCallRecord(callRecordUrl); 
        } else { 
            const result = await GetRecordingInfo(recordingUrl);
            setCallRecord(result.audioUrl);
        }

        setLoading(false);
    }

    useEffect(() => {
        if(callInfo?.callDuration > 0)
            getCallRecordLink(callInfo?.recordingUrl, callInfo?.callCompany);
        else
            setLoading(false)
    }, [])

    return (
        <tr className="bg-white hover:bg-[#f0f0f0] ">
            <td className="td h-[60px] align-middle">
                <p className={`${getAnswerStatusClassnames(callInfo?.disposition).className} font-bold text-[14px]`}>
                    {getAnswerStatusClassnames(callInfo?.disposition).string}
                </p>
            </td>
            <td className="td h-[60px] align-middle text-start">
                <p className="group relative max-w-max">
                    {isItSpecified(callInfo?.employeeName, notSpecified)}
                    {callInfo?.callerId && <span className="tooltip-top">{maskPhoneNumber(`+9${callInfo?.callerId}`)}</span>}
                </p>
            </td>
            <td className="td h-[60px] align-middle">
                <p className="min-w-max">{convertSecondsToMinutesAndSeconds(callInfo?.callDuration, strings)}</p>
            </td>
            <td className="td h-[60px] align-middle">
                <p className="min-w-max">{ISODateToReadableDateWithSeconds(callInfo?.callDate)}</p>
            </td>
            <td className="td h-[60px] align-middle">
                <p className="max-w-max">{callInfo?.callCompany === "esneks" ? "Esneks/3CX" : "Alotech"}</p>
            </td>
            <td className="td h-[60px] align-middle">
                {callRecord && callRecord.length > 0 ? (
                    <audio
                        className="w-full h-[40px]"
                        controls
                    >
                        <source
                            src={callRecord}
                            type="audio/mp3"
                        />
                        Your browser does not support the audio element.
                    </audio>
                ) : (
                    <>
                        {loading && <LoadingSpinner />}

                        {!loading && (
                            <span>{strings.member.member_detail.call_records.call_record_not_found}</span>
                        )}
                    </>
                )}
            </td>
        </tr>
    )
}

export default CallRecordsTableRow;
