import React from "react";
import { SquareButton } from "../../../../../components";
import { ISOStringToDate } from "../../../../../components/Utility";
import useAuth from "../../../../../hooks/Log/useAuth";
import { useLocalization } from "../../../../../hooks/Utils/useLocalization";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";

const TaskTableRow = ({ data, setModal, setModalData, isComplete = false }) => {

  const strings = useLocalization();
  const { employee } = useAuth();
  const dataLayer = useDataLayer();

  return (
    <tr key={data.taskId} className="bg-white hover:bg-[#f0f0f0]">
      <td className="td">
        <div className="max-w-[300px]">
          <div className="flex flex-col items-center min-w-max">
            <p className={`${isComplete ? "bg-card" : "bg-green text-white"} max-w-[250px] text-center px-3 py-1 align-middle text-[11.3px] border border-[#dddddd] rounded-full`}>
              <strong>{data.taskType}</strong>
            </p>
            <p className="font-bold mt-2">
              {strings.member.member_detail.tasks.task_date}
            </p>
            <p className="mb-2">
              {ISOStringToDate(data.dueDate, parseInt(employee.TimeZone))}
            </p>
            <div className="w-7 h-7 flex justify-center items-center bg-red border border-[#dddddd] rounded-full text-white text-[11.3px] cursor-default relative group">
              {data.numberOfPostpone}
              <div className="tooltip-top">
                <p>{strings.member.member_detail.tasks.postpone_task_number}</p>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td className="td align-middle">
        <div className="text-[12px] flex flex-col gap-2">
          <p className="italic min-w-[270px]">{data.message}</p>
          <p>
            <span className="font-bold">
              {strings.table.created_date}:{" "}
            </span>
            {ISOStringToDate(data.createDate, parseInt(employee.TimeZone))}
          </p>
          {isComplete && (
            <p>
              <span className="font-bold">
                {strings.table.completed_date}:{" "}
              </span>
              {data?.completedDate ? ISOStringToDate(data.completedDate, parseInt(employee.TimeZone)) : strings.errors.it_is_not_specified}
            </p>
          )}
        </div>
      </td>
      <td className="td align-middle">
        <p className="min-w-max">{data.employee.name}</p>
      </td>
      {!isComplete && (
        <td className="td align-middle">
          <SquareButton
            classnames="bg-blue"
            iconClassnames="text-[22px]"
            iconName="play_arrow"
            tooltip={strings.member.member_detail.tasks.start_task}
            action={() => {
              setModal(true);
              setModalData({
                taskId: data.taskId,
                memberId: data.member.id,
                memberName: data.member.fullName,
                message: data.message,
                showAiAnalysis: data?.isTaskCompatibleToAiAnalysis,
                showWeeklyAiAnalysis: data?.isTaskCompatibleToWeeklyAiAnalysis
              });
              // DATA LAYER LOGGED
              dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.START_TASK);
            }}
          />
        </td>
      )}
    </tr>
  );
};

export default TaskTableRow;
