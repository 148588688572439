import {NoRecordsFound} from "../../../../../components";
import React from "react";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import CallRecordsTableRow from "./CallRecordsTableRow";

const CallRecordsTable = ({ data, esneksRecordingPath }) => {

    const strings = useLocalization();

    return (
        <table className="min-w-full leading-normal text-header">
            <thead>
            <tr>
                <th className="th sm:w-[14%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_status}</p></th>
                <th className="th sm:w-[14%]"><p className="min-w-max">{strings.member.member_detail.call_records.caller_name}</p></th>
                <th className="th sm:w-[14%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_duration}</p></th>
                <th className="th sm:w-[14%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_date}</p></th>
                <th className="th sm:w-[14%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_provider}</p></th>
                <th className="th sm:w-[28%]"><p className="min-w-max">{strings.member.member_detail.call_records.call_record}</p></th>
            </tr>
            </thead>
            <tbody>

            {data.length > 0 ? (
                data.map((callInfo, index) => (
                    <CallRecordsTableRow
                        key={index}
                        callInfo={callInfo}
                        esneksRecordingPath={esneksRecordingPath}
                    />
                ))
            ) : (
                <tr className="bg-white hover:bg-[#f0f0f0]">
                    <td className="td" colSpan={6}>
                        <NoRecordsFound/>
                    </td>
                </tr>
            )}


            </tbody>
        </table>
    );
}

export default CallRecordsTable;